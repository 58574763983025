<template>
  <div class="portfolio-pie-chart">
    <Pie :data="chartData" :options="options" />
    <p style="text-align: center">
      <strong>{{ performanceText }}</strong>
      <br /><span style="font-weight: bold; font-size: 2rem; color: #00e383">{{
        performanceNumber
      }}</span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Pie } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  name: 'PortfolioPieChart',
  components: {
    Pie,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    performanceText: {
      type: String,
      required: true,
    },
    performanceNumber: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: ['Actions', 'ETF', 'Obligations', 'Immobilier'],
        datasets: [
          {
            data: this.data,
            backgroundColor: ['#00e383', '#00c378', '#00a36d', '#008362'],
          },
        ],
      };
    },
    options() {
      return {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.label || '';
                let value = context.raw || 0;
                let total = context.dataset.data.reduce((a, b) => a + b, 0);
                let percentage = ((value / total) * 100).toFixed(2) + '%';
                return `${label}: ${percentage}`;
              },
            },
          },
          datalabels: {
            display: true,
            formatter: (value, ctx) => {
              let datasets = ctx.chart.data.datasets;
              if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                let percentage = ((value / sum) * 100).toFixed(2) + '%';
                return percentage;
              } else {
                return '';
              }
            },
            color: '#fff',
            font: {
              weight: 'bold',
            },
          },
          legend: {
            display: false,
          },
        },
      };
    },
  },
});
</script>

<style scoped>
.portfolio-pie-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
canvas {
  max-width: 400px;
}
</style>
