<template>
  <div>
    <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },
  props: ['chartData'],
  setup(props) {
    const chartOptions = computed(() => {
      return {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: props.chartData.labels,
          title: {
            text: 'Années',
          },
        },
        yaxis: {
          title: {
            text: 'Capital (€)',
          },
          min: 0,
          labels: {
            formatter: function (val) {
              return val >= 1000
                ? (val / 1000).toFixed(0) + 'k'
                : val.toFixed(0);
            },
          },
        },
        stroke: {
          curve: 'smooth',
        },
      };
    });

    const series = computed(() => [
      {
        name: 'Capital avec réinvestissement',
        data: props.chartData.dataInvestmentWithHigherReinvestment,
        color: '#33beff',
      },
      {
        name: 'Capital avec Investissement',
        data: props.chartData.dataInvestment,
        color: '#269746',
      },
      {
        name: 'Capital sans Investissement',
        data: props.chartData.dataSavings,
        color: '#8B4513',
      },
    ]);

    return {
      chartOptions,
      series,
    };
  },
});
</script>

<style scoped>
div {
  height: 400px;
}
</style>
