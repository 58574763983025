<template>
  <div class="app-container">
    <!-- Sidebar Menu -->
    <nav class="sidebar">
      <div class="text-center punch">
        <img
          src="./assets/logo-retraitegarantie.png"
          alt="Retraite Garantie épargnez intelligement pour assurer votre futur."
        />
      </div>
      <ul>
        <li
          :class="{ active: activeSection === 'savings-calculator' }"
          @click="scrollToSection('savings-calculator')"
        >
          <i class="fas fa-calculator"></i>
        </li>
        <li
          :class="{ active: activeSection === 'investment-portfolios' }"
          @click="scrollToSection('investment-portfolios')"
        >
          <i class="fas fa-chart-pie"></i>
        </li>
        <li
          :class="{ active: activeSection === 'compound-interest' }"
          @click="scrollToSection('compound-interest')"
        >
          <i class="fas fa-chart-line"></i>
        </li>
        <li
          :class="{ active: activeSection === 'broker-recommendations' }"
          @click="scrollToSection('broker-recommendations')"
        >
          <i class="fas fa-user-tie"></i>
        </li>
        <li
          :class="{ active: activeSection === 'other-tools' }"
          @click="scrollToSection('other-tools')"
        >
          <i class="fas fa-tools"></i>
        </li>
      </ul>
    </nav>

    <div class="content" @scroll="handleScroll">
      <!-- Savings Calculator Section -->
      <section id="savings-calculator" class="section">
        <div class="description mt-3">
          <h1>
            <i class="fas fa-calculator"></i> Calculer mon investissement long
            terme<br />
            <span
              >Comparez les différences entre épargner sans investir et avec
              intérêts composés</span
            >
          </h1>
          <p>
            Utilisez cet outil pour comparer l'épargne mensuelle en
            investissant, avec intérêts et sans investissement et voir comment
            votre capital peut croître avec les intérêts composés.
            <br />
            <strong>
              À partir de seulement 10 annèes d'investissement avec intérêts
              composés vous pouvez doubler votre épargne.
            </strong>
          </p>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 form-container">
            <form @submit.prevent>
              <div class="form-group">
                <label for="initialInvestment"
                  >Investissement Initial (€):</label
                >
                <input
                  v-model.number="initialInvestment"
                  type="number"
                  class="form-control"
                  id="initialInvestment"
                  placeholder="0"
                />
              </div>
              <div class="form-group">
                <label for="monthlySavings">Épargne Mensuelle (€):</label>
                <input
                  v-model.number="monthlySavings"
                  type="number"
                  class="form-control"
                  id="monthlySavings"
                  placeholder="500"
                />
              </div>
              <div class="form-group">
                <label for="annualRate">Bénéfices annuels (%):</label>
                <input
                  v-model.number="annualRate"
                  type="number"
                  class="form-control"
                  id="annualRate"
                  step="0.1"
                  placeholder="7"
                />
              </div>
              <div class="form-group">
                <label for="compoundInterest">
                  Intérêts composés (%)
                  <span
                    class="info-hint"
                    data-bs-toggle="tooltip"
                    title="Les intérêts composés incluent les dividendes, les loyers, et les coupons etc.. réinvestis en début de chaque année."
                  >
                    &#9432;
                  </span>
                </label>
                <input
                  v-model.number="compoundInterestRate"
                  type="number"
                  class="form-control"
                  id="compoundInterest"
                  step="0.1"
                  placeholder="4.5"
                />
              </div>
              <div class="form-group">
                <label for="years">Nombre d'Années:</label>
                <input
                  type="range"
                  v-model.number="years"
                  class="custom-range"
                  id="years"
                  min="0"
                  max="50"
                />
                <div class="d-flex justify-content-between">
                  <span>0</span>
                  <span>{{ years }} ans</span>
                  <span>50</span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-8" style="max-width: 720px">
            <investment-chart :chartData="chartData"></investment-chart>
          </div>
        </div>
        <div class="results-container mt-4">
          <div class="result-item">
            <p class="result-title">Capital épargne sans Investissement:</p>
            <span class="result-value sansInvest">{{
              formatCurrency(capitalWithoutInvestment)
            }}</span>
          </div>
          <div class="result-item">
            <p class="result-title">
              Capital épargne avec investissement<br />
              (sans réinvestissement):
            </p>
            <span class="result-value invest">{{
              formatCurrency(capitalWithInvestment)
            }}</span>
          </div>

          <div class="result-item highlighted">
            <p class="result-title">
              Capital épargne avec investissement + réinvestissement des
              bénéfices
            </p>
            <span class="result-value investInt">{{
              formatCurrency(capitalWithHigherReinvestment)
            }}</span>
          </div>
        </div>
      </section>

      <!-- Investment Portfolios Section -->
      <section id="investment-portfolios" class="section">
        <div class="description mt-3">
          <h1>
            <i class="fas fa-chart-line"></i>Comment créer un portefeuille pour
            investir?<br />
            <h4>
              Choisissez parmis nos recommandations de portefeuille sécurisé
              pour un rendement sûr
            </h4>
          </h1>
          <p>
            Découvrez nos conseils de portefeuilles d'investissement
            raisonnables.<br class="desk" />
            Conçus pour garantir un rendement optimal tout en minimisant les
            risques.<br class="desk" />
            Investissez intelligemment et assurez votre avenir financier avec
            nos portefeuilles recommandés.
          </p>

          <h3><i class="fas fa-chart-line"></i> Actions</h3>
          <p>
            Les actions représentent une part de propriété dans une entreprise.
            Elles offrent un potentiel de rendement élevé mais comportent
            également un risque plus élevé. Historiquement, les actions ont
            fourni un rendement annuel moyen de 7 à 10 % sur le long terme.
            Cependant, elles peuvent être volatiles à court terme, en
            particulier pendant les périodes de crise économique.
          </p>
          <span>Rendement annuel moyen sur les 10 dernières années : 8 %</span>
          <br />
          <h3><i class="fas fa-file-alt"></i>Obligations</h3>
          <p>
            Les obligations sont des titres de créance émis par des entreprises
            ou des gouvernements. Elles sont généralement considérées comme
            moins risquées que les actions. Les obligations offrent des
            paiements d'intérêts réguliers et un remboursement du principal à
            l'échéance. Pendant les périodes de crise, les obligations peuvent
            offrir une stabilité et servir de refuge.
          </p>
          <span
            >Rendement annuel moyen sur les 10 dernières années : 3-5 %</span
          >
          <br />
          <h3>
            <i class="fas fa-exchange-alt"></i>ETF (Exchange-Traded Funds)
          </h3>
          <p>
            Les ETF sont des fonds négociés en bourse qui détiennent un panier
            d'actifs. Ils offrent une diversification instantanée à moindre
            coût. Les ETF peuvent suivre des indices de marché, des secteurs
            spécifiques ou des classes d'actifs variées. Ils sont flexibles et
            peuvent être achetés et vendus comme des actions, offrant une
            liquidité quotidienne.
          </p>
          <span
            >Rendement annuel moyen sur les 10 dernières années : 5-7 %</span
          >
          <br />
          <h3><i class="fas fa-home"></i>Immobiliers</h3>
          <p>
            L'immobilier inclut l'investissement dans des propriétés physiques
            ou des REIT (Real Estate Investment Trusts). Les investissements
            immobiliers peuvent offrir des revenus locatifs réguliers et une
            appréciation du capital à long terme. Ils peuvent également agir
            comme un hedge contre l'inflation.
          </p>
          <span>Rendement annuel moyen sur les 10 dernières années : 6 %</span>
          <br />
          <h3><i class="fas fa-cubes"></i>Matières Premières</h3>
          <p>
            Les matières premières comprennent l'or, l'argent, le pétrole, et
            d'autres ressources naturelles. Elles offrent une diversification
            supplémentaire et peuvent servir de couverture contre l'inflation et
            les crises économiques. Les matières premières sont souvent
            considérées comme un investissement défensif.
          </p>
          <span>Rendement annuel moyen sur les 10 dernières années : 6 %</span>
          <br />
          <hr />
          <h3>Pourquoi diversifier ?</h3>
          <p>
            La diversification consiste à répartir vos investissements sur
            différentes classes d'actifs pour réduire le risque global de votre
            portefeuille. Chaque type d'investissement réagit différemment aux
            conditions économiques, ce qui signifie que lorsque certains
            investissements baissent, d'autres peuvent monter. En diversifiant,
            vous pouvez protéger votre portefeuille contre les pertes
            importantes et augmenter vos chances de rendement stable à long
            terme.
          </p>
          <div class="legend">
            <div class="legend-item">
              <span
                class="legend-color"
                style="background-color: #00e383"
              ></span>
              Actions
            </div>
            <div class="legend-item">
              <span
                class="legend-color"
                style="background-color: #00c378"
              ></span>
              ETF
            </div>
            <div class="legend-item">
              <span
                class="legend-color"
                style="background-color: #00a36d"
              ></span>
              Obligations
            </div>
            <div class="legend-item">
              <span
                class="legend-color"
                style="background-color: #008362"
              ></span>
              Immobilier
            </div>
          </div>
        </div>
        <div class="portfolio-charts">
          <portfolio-pie-chart
            v-for="(portfolio, index) in portfolios"
            :key="index"
            :data="portfolio.data"
            :title="portfolio.title"
            :performanceText="portfolio.performanceText"
            :performanceNumber="portfolio.performanceNumber"
          ></portfolio-pie-chart>
        </div>
      </section>

      <!-- Compound Interest Section -->
      <section id="compound-interest" class="section">
        <div class="description mt-3">
          <h1>
            <i class="fas fa-chart-pie"></i> Intérêts Composés<br />
            <span style="color: #00a36d"
              >Comprendre et Maximiser vos Gains</span
            >
          </h1>
          <p>
            Les intérêts composés permettent à votre capital de croître de
            manière exponentielle en réinvestissant les gains obtenus. Cela
            signifie que vous gagnez des intérêts non seulement sur votre
            capital initial, mais aussi sur les intérêts accumulés au fil du
            temps.
          </p>
          <p>
            Bien qu'il soit tentant de retirer les gains annuels, il est souvent
            plus avantageux de réinvestir les dividendes, les coupons ou les
            loyers. Cela permet d'éviter les frais de transaction et de
            maximiser la croissance à long terme.
          </p>
        </div>
      </section>

      <!-- Broker Recommendations Section -->
      <section id="broker-recommendations" class="section">
        <div class="description mt-3">
          <h1>
            <i class="fas fa-user-tie"></i> Recommandations de Brokers<br />
            <span>Trouvez le broker qui vous convient</span>
          </h1>
          <p>
            Consultez nos recommandations de brokers pour commencer vos
            investissements en toute confiance.
          </p>
          <!-- Ajoutez ici les recommandations de brokers -->
        </div>
      </section>

      <!-- Other Tools Section -->
      <section id="other-tools" class="section">
        <div class="description mt-3">
          <h1>
            <i class="fas fa-tools"></i> Autres outils<br />
            <span
              >Explorez d'autres outils utiles pour vos investissements</span
            >
          </h1>
          <p>
            Découvrez une gamme d'outils complémentaires pour vous aider dans
            vos décisions d'investissement.
          </p>
          <!-- Ajoutez ici les autres outils -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import * as bootstrap from 'bootstrap';
import InvestmentChart from './components/InvestmentChart.vue';
import PortfolioPieChart from './components/PortfolioPieChart.vue';

export default defineComponent({
  components: {
    InvestmentChart,
    PortfolioPieChart,
  },
  setup() {
    const initialInvestment = ref(0);
    const monthlySavings = ref(500);
    const annualRate = ref(7);
    const compoundInterestRate = ref(4.5);
    const years = ref(25);

    const activeSection = ref('savings-calculator');

    const handleScroll = () => {
      const content = document.querySelector('.content');
      const sections = document.querySelectorAll('.section');
      const scrollPosition = content.scrollTop;

      console.log('Scroll Position:', scrollPosition);
      console.log('Sections:', sections);

      let currentSection = '';

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        console.log(
          `Section: ${section.getAttribute(
            'id'
          )}, Top: ${sectionTop}, Height: ${sectionHeight}`
        );

        if (
          scrollPosition >= sectionTop - sectionHeight / 3 &&
          scrollPosition < sectionTop + sectionHeight - sectionHeight / 3
        ) {
          currentSection = section.getAttribute('id');
          console.log('Current Section Match:', currentSection);
        }
      });

      if (currentSection && activeSection.value !== currentSection) {
        activeSection.value = currentSection;
        console.log('Active Section Updated:', activeSection.value);
      } else {
        console.log('No Section Change:', activeSection.value);
      }
    };

    onMounted(() => {
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new bootstrap.Tooltip(tooltipTriggerEl);
      });

      const content = document.querySelector('.content');
      content.addEventListener('scroll', handleScroll);
    });

    const scrollToSection = (sectionId) => {
      document.getElementById(sectionId).scrollIntoView({
        behavior: 'smooth',
      });
    };

    const capitalWithInvestment = computed(() => {
      const monthlyRate = Math.pow(1 + annualRate.value / 100, 1 / 12) - 1;
      let futureValueInvestment = initialInvestment.value;

      for (let year = 1; year <= years.value; year++) {
        for (let month = 1; month <= 12; month++) {
          futureValueInvestment =
            (futureValueInvestment + monthlySavings.value) * (1 + monthlyRate);
        }
      }

      return futureValueInvestment;
    });

    const capitalWithHigherReinvestment = computed(() => {
      const monthlyRate = Math.pow(1 + annualRate.value / 100, 1 / 12) - 1;
      let futureValueInvestment = initialInvestment.value;

      for (let year = 1; year <= years.value; year++) {
        for (let month = 1; month <= 12; month++) {
          futureValueInvestment =
            (futureValueInvestment + monthlySavings.value) * (1 + monthlyRate);
        }
        futureValueInvestment *= 1 + compoundInterestRate.value / 100;
      }

      return futureValueInvestment;
    });

    const capitalWithoutInvestment = computed(() => {
      const months = years.value * 12;
      return monthlySavings.value * months + initialInvestment.value;
    });

    const chartData = computed(() => {
      const labels = [];
      const dataInvestment = [];
      const dataInvestmentWithHigherReinvestment = [];
      const dataSavings = [];

      const monthlyRate = Math.pow(1 + annualRate.value / 100, 1 / 12) - 1;
      let futureValueInvestment = initialInvestment.value;
      let futureValueInvestmentWithHigherReinvestment = initialInvestment.value;
      let futureValueSavings = initialInvestment.value;

      for (let year = 0; year <= years.value; year++) {
        labels.push(year.toString());

        if (year > 0) {
          for (let month = 1; month <= 12; month++) {
            futureValueInvestment =
              (futureValueInvestment + monthlySavings.value) *
              (1 + monthlyRate);
            futureValueInvestmentWithHigherReinvestment =
              (futureValueInvestmentWithHigherReinvestment +
                monthlySavings.value) *
              (1 + monthlyRate);
            futureValueSavings += monthlySavings.value;
          }
          futureValueInvestmentWithHigherReinvestment *=
            1 + compoundInterestRate.value / 100;
        }

        dataInvestment.push(futureValueInvestment);
        dataInvestmentWithHigherReinvestment.push(
          futureValueInvestmentWithHigherReinvestment
        );
        dataSavings.push(futureValueSavings);
      }

      return {
        labels: labels,
        dataInvestment: dataInvestment,
        dataInvestmentWithHigherReinvestment:
          dataInvestmentWithHigherReinvestment,
        dataSavings: dataSavings,
      };
    });

    const portfolios = ref([
      {
        title: 'Portefeuille 3',
        data: [25, 25, 25, 25],
        description:
          'Portefeuille 3: 25% Actions, 25% ETF, 25% Obligations, 25% Immobilier.',
        performanceText: 'Rendement moyen sur les 10 dernières années',
        performanceNumber: '+6%',
      },
      {
        title: 'Portefeuille 2',
        data: [40, 20, 20, 20],
        description:
          'Portefeuille 2: 40% Actions, 20% ETF, 20% Obligations, 20% Immobilier.',
        performanceText: 'Rendement moyen sur les 10 dernières années',
        performanceNumber: '+6.5%',
      },
      {
        title: 'Portefeuille 1',
        data: [30, 20, 20, 30],
        description:
          'Portefeuille 1: 30% Actions, 20% ETF, 20% Obligations, 30% Immobilier.',
        performanceText: 'Rendement moyen sur les 10 dernières années',
        performanceNumber: '+7%',
      },

      {
        title: 'Portefeuille 4',
        data: [50, 10, 20, 20],
        description:
          'Portefeuille 4: 50% Actions, 10% ETF, 20% Obligations, 20% Immobilier.',
        performanceText: 'Rendement moyen sur les 10 dernières années',
        performanceNumber: '+7.5%',
      },
    ]);

    const formatCurrency = (amount) => {
      return amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' €';
    };

    return {
      initialInvestment,
      monthlySavings,
      annualRate,
      compoundInterestRate,
      years,
      capitalWithInvestment,
      capitalWithHigherReinvestment,
      capitalWithoutInvestment,
      chartData,
      portfolios,
      formatCurrency,
      activeSection,
      scrollToSection,
      handleScroll,
    };
  },
});
</script>

<style scoped>
@import './styles.css';

.app-container {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100px;
  background: rgb(0, 219, 126);
  background: linear-gradient(180deg, rgb(0 228 130) 0%, rgb(134 174 255) 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 20px;
}

.sidebar ul li {
  width: 100%;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar ul li:hover,
.sidebar ul li.active {
  background-color: rgba(63, 63, 63, 0.5);
}

.sidebar ul li i {
  font-size: 1.25rem;
}

.sidebar ul li span {
  font-size: 16px;
}

.content {
  background: #ffffff;
  margin-left: 115px;
  padding: 20px 50px;
  width: 100%;
  overflow-y: auto;
  height: 100vh;
}

.section {
  padding: 20px 0;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.section:not(:first-child) {
  border-top: 1px dashed #ccc;
}

/* Styles existants */
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.description {
  margin-bottom: 40px;
  max-width: 100%;
  text-align: left;
}

.results-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.results-container .result-item {
  flex: 1;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.results-container .result-item.highlighted {
  background-color: #ffffff;
  border: 2px solid #33beff;
}

.result-title {
  font-weight: bold;
  color: #333;
}

.result-value {
  font-size: 1.5em;
}

.result-savings .result-value {
  color: #8b4513;
}

.sansInvest {
  color: #8b4513;
}
.invest {
  color: #269746;
}

.investInt {
  color: #33beff;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.info-hint {
  cursor: pointer;
  margin-left: 5px;
  color: #ffffff;
  font-weight: bold;
}

.punch {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}

.punch img {
  max-width: 80px;
}

hr {
  border-top: var(--bs-border-width) dashed;
  margin: 30px;
}

.en-tete-punchline {
  font-size: 2em;
}

.nixie-one-regular {
  font-family: 'Nixie One', system-ui;
  font-weight: 400;
  font-style: normal;
}

.aleo {
  font-family: 'Aleo', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
  color: #2e2e2e;
}

.description span {
  font-size: 1.2rem;
}

.portfolio-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.legend {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 20px;
  width: 50%;
}
.legend-item {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}
.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: inline-block;
}
.portfolio-pie-chart {
  width: 19%;
  margin: 20px 0;
  max-width: 175px;
}
.portfolio-pie-chart p {
  font-weight: bold;
}
.app-container {
  background: #ffffff;
}
#compound-interest {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}
.description i {
  margin-right: 10px;
}
.description h3 {
  margin-top: 20px;
}
.description span {
  font-weight: bold;
  color: #686868;
}
.sidebar ul li.active {
  background-color: rgba(63, 63, 63, 0.5);
}

.form-container {
  background: #85affe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
#savings-calculator label,
span {
  color: white;
}
/* bouton du slider du calculateur */
.custom-range {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right, #33beff, #269746);
  height: 8px;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.custom-range:hover {
  opacity: 1;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #444444; /* Couleur du bouton */
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s;
}

.custom-range::-webkit-slider-thumb:hover {
  background: #444444; /* Couleur du bouton au survol */
}

.custom-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #444444; /* Couleur du bouton */
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s;
}

.custom-range::-moz-range-thumb:hover {
  background: #444444; /* Couleur du bouton au survol */
}

.custom-range {
  background: linear-gradient(to right, #33beff, #269746);
}

#savings-calculator {
  background: white;
  background-size: cover;
  background-position: center;
  padding: 30px;

  color: #333;
}

#savings-calculator .description {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.result-item {
  background: #ffffff;
}

.result-item.highlighted {
  background: #dff0ff;
}
.app-container {
  background-image: url('./assets/background.jpg');
}

@media (max-width: 900px) {
  .results-container .result-item {
    flex: 100%;
  }
  .desk {
    display: none;
  }
}
</style>
